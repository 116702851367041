class tabsDisplay{

  constructor() {
    this.tabs = document.querySelectorAll(".tabs a, .navbar .panellink");
    this.panels = document.querySelectorAll(".display-container .panels .panel");

    if (this.panels.length ===0) {
      return
    }

    this.tabs.forEach((tab) => {
      tab.addEventListener("click", (e) => {
        this.handleClick(tab, e);
      });
      if (tab.classList.contains("active")) {
        document.querySelector("#" + tab.dataset.targetId).
          classList.remove("noshow");
      };
    });
  }

  // Adds active class to clicked tab, removes it from all others.
  // Finds the panel with the corresponding data-target-id,
  // and removes 'noshow' class from this panel, adds it to all others
  handleClick(elem, e) {
    e.preventDefault();

    let togglee = document.querySelector("#" + elem.dataset.targetId);

    this.tabs.forEach((tab) => {
      if (tab == elem) {
        tab.classList.add("active");
      } else {
        tab.classList.remove("active");
      }
    });

    this.panels.forEach((panel) => {
      if (panel == togglee) {
        // panel.style.display = "block";

        // set src link
        let s = elem.dataset.source
        let s_qry = elem.dataset.sourceElem
        if (s && s_qry) {
          panel.querySelector(s_qry).src = s;
        }

        panel.classList.remove("noshow");
        panel.style.opacity = 1;
      } else {
        // panel.style.display = "none";
        panel.classList.add("noshow")
      }
    });
  }
}

window.addEventListener("turbo:load", () => new tabsDisplay());
