class AssetFormCallbacks {

  constructor() {
    this.method_selector = document.querySelector(
      "#asset-method-field > select"
    );

    if (this.method_selector) {
      this.method_selector.addEventListener('change', (event) => {
        //console.log("Event: ", event)
        this.method_selected(event.target);
      })
      this.method_selected(this.method_selector);
    }
  }

  method_selected(tgt) {

    // UnitsOfProduction: Change period label to Estimated Lifetime
    let period_label = document.querySelector(
      "#asset-recovery-period-field > label"
    );
    if (period_label) {
      let lbl = "Recovery period (years)";

      if (tgt.options[tgt.selectedIndex].value == "UnitsOfProduction") {
        lbl = "Estimated lifetime (years)";
      }
      period_label.innerHTML = lbl;
    }

    // grey-out Convention when not needed
    let convention = document.querySelector("#asset-convention-field")
    if (convention) {
      let opacty = 1;

      if (["UnitsOfProduction", "SumOfYearsDigits", "MonthsInService"].includes(
        tgt.options[tgt.selectedIndex].value
      )) {
        opacty = 0.2;
      }
      convention.style.opacity = opacty;
    }

    let lifetime = document.querySelector("#asset-lifetime-units-field")
    if (lifetime) {

      if (tgt.options[tgt.selectedIndex].value != "UnitsOfProduction") {
        lifetime.classList.add("scale-y-zero")
      } else {
        lifetime.classList.remove("scale-y-zero")
      }
    }
  }
}

document.addEventListener("turbo:load", () => new AssetFormCallbacks());
