// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"

//Rails.start()
// ActiveStorage.start()

require("./src/nested_forms")
require("./src/toggle_display")
require("./src/modal")
require("./src/chart_util")
require("./src/flash_messages")
require("./src/asset_form_callbacks")
require("./src/tabs")
require("./src/list_counter")

import './src/bootstrap_js_files.js'

// import 'bootstrap-icons/font/bootstrap-icons.css'  enable if using
//                                                    bootstrap icons
