class toggleDisplay {

  constructor() {
    this.elements = document.querySelectorAll(".toggle-display");
    this.set_handlers();
  }

  set_handlers() {

    this.elements.forEach((elem) => {
      elem.addEventListener("click", (e) => {
        this.handleClick(elem, e);
        this.toggle_toggler(elem);
      });

      // at page-load, add initial classes to class attribute.
      // important: When page is loading, these initial classes
      // cannot be in class attribute, but must be in data-initial-classes
      this.toggle_toggler(elem);
    });
  }


  // finds all elements with the corresponding data-target-id,
  // and toggles display property between 'block' and 'none'
  handleClick(elem, e) {
    e.preventDefault();

    let togglees = document.querySelectorAll("#" + elem.dataset.targetId);
    let new_value, togglee;

    togglees.forEach((togglee) => {
      new_value = (togglee.style.display == "none") ? "block" : "none"
      togglee.style.display = new_value
    });
  }

  toggle_toggler(elem) {
    let ini = elem.dataset.initialClasses.split(" ");
    let alt = elem.dataset.alternateClasses.split(" ");

    const reducer = (accum, current) =>
        accum && elem.classList.contains(current);

    if (ini.reduce(reducer, true)) {

      // all ini elements are in the classList
      elem.classList.remove(...ini);
      elem.classList.add(...alt);

    } else {

      elem.classList.remove(...alt);
      elem.classList.add(...ini);
    }
  }
}

window.addEventListener("turbo:load", () => new toggleDisplay());
