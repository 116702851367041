import 'bootstrap/js/src/alert'
// import 'bootstrap/js/src/button'
import 'bootstrap/js/src/carousel'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/dropdown'
// import 'bootstrap/js/src/modal'
// import 'bootstrap/js/src/popover'
import 'bootstrap/js/src/scrollspy'
// import 'bootstrap/js/src/tab'
import Toast from 'bootstrap/js/src/toast'
// import Tooltip from 'bootstrap/js/src/tooltip'

// show bootstrap Toast/Tooltips elements
// if this EventListener is moved to application.js,
// 'Toast not defined' occurs.
window.addEventListener("turbo:load", function () {
  var tElems = document.querySelectorAll('.toast');
  tElems.forEach((tEl) => {
      (new Toast(tEl)).show()
  })
})
