class FlashMessage {

  constructor() {
    this.showClass = "flash-message--is-visible";
    this.duration = 5000;
    this.elements = document.querySelectorAll(".js-flash-message");
    // console.log('In FlashMessage constructor ' + this.elements);
    this.margin = 5; //pixels
    this.flash();
  }

  flash() {

    var bot = 40; // pixels from bottom

    this.elements.forEach((elem) => {

      elem.style.bottom = bot.toString() + 'px';
      bot = bot + elem.getBoundingClientRect().height + this.margin;

      elem.classList.remove("js-flash-message");

      elem.classList.add(this.showClass);
      setTimeout(function(){
        elem.classList.remove("flash-message--is-visible")
        // don't know how to read this.showClass from scope of setTimeout
        // also, couldn't call function in FlashMessage to hide message.
       }, this.duration);
    });
  }
}

document.addEventListener("turbo:load", () => new FlashMessage());
//document.addEventListener("turbo:load", () => console.log('turbo:load'));
//window.addEventListener("turbo:load", f = new FlashMessage());
// document.addEventListener("turbo:before-fetch-response", (event) => {
//   console.log('turbo:before_fetch-response', event.target);
// });

// workaround. turbo:load event is not triggered after turbo-visit,
// so using the one event that is triggered.
document.addEventListener("turbo:before-fetch-response", (event) => {
  setTimeout(function() {
    new FlashMessage();
  }, 600)
});
