class modalController {

  constructor() {
    this.elements = document.querySelectorAll(".pop-modal");
    this.elements.forEach((elem) => {

      let modal = document.querySelector("#" + elem.dataset.targetId);

      if (modal.classList.contains('carousel')) {
        elem.addEventListener("click", (e) => {
          this.handleClickCarousel(elem, e);
        });
        modal.addEventListener('slide.bs.carousel', (e) => {
          this.carouselSetImgSrc(e.relatedTarget.
                                 querySelector("img[data-src]"))
        });
      } else {
        elem.addEventListener("click", (e) => {
          this.handleClick(elem, e);
        });
      }
    });
  }

  handleClick(elem, e) {

    e.preventDefault();

    let modal    = document.querySelector("#" + elem.dataset.targetId);
    let modalImg = document.querySelector("#" + elem.dataset.targetId +
                        "> .image-modal-content");
    let modalX   = document.querySelector("#" + elem.dataset.targetId +
                        "> .image-modal-close");

    modalImg.src = elem.dataset.modalUrl

    modalX.onclick = function() {
      modal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }

    modal.style.display = "block";

  }

  handleClickCarousel(elem, e) {

    e.preventDefault();

    let modal    = document.querySelector("#" + elem.dataset.targetId);
    let modalImg = document.querySelector("#" + elem.dataset.targetId +
                        "> .carousel-inner > .active > img[data-src]");
    let modalX   = document.querySelector("#" + elem.dataset.targetId +
                        "> .image-modal-close");

    this.carouselSetImgSrc(modalImg);

    modalX.onclick = function() {
      modal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }

    modal.style.display = "block";

  }

  carouselSetImgSrc(imgElem) {

    // imgElem is nil after the first carousel round
    if (!imgElem) return;

    imgElem.src = imgElem.dataset.src;
    imgElem.removeAttribute('data-src');
  }

}

window.addEventListener("turbo:load", () => new modalController());
