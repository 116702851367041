window.addEventListener("turbo:load", function () {

  Chart.defaults.elements.bar.borderWidth = 2;
  Chart.defaults.elements.bar.barThickness = 2;
  Chart.defaults.elements.bar.borderColor = [
            "rgb(255, 99, 132)", "rgb(255, 159, 64)",
            "rgb(255, 205, 86)", "rgb(75, 192, 192)", "rgb(54, 162, 235)",
            "rgb(153, 102, 255)", "rgb(201, 203, 207)"];
  Chart.defaults.elements.bar.backgroundColor = [
            "rgba(255, 99, 132, 0.2)", "rgba(255, 159, 64, 0.2)",
            "rgba(255, 205, 86, 0.2)", "rgba(75, 192, 192, 0.2)", "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)", "rgba(201, 203, 207, 0.2)"];
  Chart.defaults.datasets.fill = false;
  Chart.defaults.plugins.legend.display = false;
  Chart.defaults.plugins.title.display = false;

  var canvases = document.querySelectorAll(".horizontalBar");

  canvases.forEach((canvas) => {

    new Chart(canvas, {
      type: "bar",
      data: ChartData.sets[canvas.id],
      options: ChartData.options,
    });

			canvas.addEventListener("click", function (evt) {  // do not use arrow function
                                                         // this not def'd for arrow fcts
					var chart = Chart.getChart(this);

					var points = chart.getElementsAtEventForMode(
							           evt, 'nearest', { intersect: true }, true);
					if (points.length) {
							const firstPoint = points[0];
							var label = chart.data.labels[firstPoint.index];
							var value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
					}

      // var url = "http://example.com/?label=" + activePoints[0].label + "&value=" + activePoints[0].value;
      // alert(url);
      });
  });

});
