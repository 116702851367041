import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = [ "togglee" ]

  // switch classes of the toggler element itself, and remember state by
  // setting classes 'initial' and 'alternate'
  toggle_toggler() {
    let ini = this.element.dataset.initialClasses.split(" ");
    let alt = this.element.dataset.alternateClasses.split(" ");

    if(this.element.classList.contains("initial")) {
      this.element.classList.remove(...ini, "initial");
      this.element.classList.add(...alt, "alternate");
    } else {
      // also used to initialize
      this.element.classList.remove(...alt, "alternate");
      this.element.classList.add(...ini, "initial");
    }
  }

  // toggle display property between 'block' and 'none' of connected outlets
  handleClick(event) {
    event.preventDefault();

    let new_value;

    this.toggleeOutletElements.forEach((t) => {
      new_value = (t.style.display == "none") ? "block" : "none"
      t.style.display = new_value
    });

    this.toggle_toggler()
  }

  initialize() {
    // initialize class list
    this.toggle_toggler()
  }
}
