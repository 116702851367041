const CB_counter_on_list_change = function(mutationsList, observer) {

  // Note: callback is executed only when element is mutated,
  // NOT at page-load.
  // This works for lazy-loading.
  // If a counter is to be displayed at page-load, it must be otherwise
  // initialized.

  // disconnect while updating the counter to avoid race condition.
  // Observe() when update is complete.
  observer.disconnect();

  let counters = document.querySelectorAll("span.list-counter");

  counters.forEach((c) => {
    let targetNode = document.getElementById(c.dataset.targetId);
    if (targetNode) {
      c.innerHTML = targetNode.children.length;
    }
  });

  // reconnect
  observer.observe(observer.observedNode, { attributes: false,
                                            childList: true,
                                            subtree: true });
}

class listCounters{

  constructor(e) {
    let main_node = document.querySelector("body > main");
    // not <body> to avoid catching profiler updates

    if (main_node) {
      let observer = new MutationObserver(CB_counter_on_list_change);
      observer.observedNode = main_node;
      observer.observe(main_node, { attributes: false,
                                    childList: true,
                                    subtree: true });
    };
  }
}

window.addEventListener("turbo:load", () => new listCounters());
